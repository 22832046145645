<template>
  <footer class="footer ">
    <div class="footer__logo">
      <img class="footer__logo__image" src="../img/logo.svg" alt="logo" />
      <!-- <img class="footer__logo__image" src="../img/logo_big.svg" alt="logo">
            <h2 class="footer__logo__text">{{ $t('menu.logo-title') }}</h2> -->
    </div>
    <nav class="footer__nav">
      <a
        href="https://btrx.itexp.pro/stream/"
        class="footer__nav__item"
        >{{ $t("menu.bitrix") }}</a
      >
      <a href="#content-block" class="footer__nav__item">{{
        $t("menu.content")
      }}</a>
    </nav>
    <div class="footer__copyrights">© 2024. All Rights Reserved. FinBeat</div>
    <div class="footer__socials">
      <socials />
    </div>
  </footer>
</template>

<script>
import socials from "./socials.vue";
export default {
  components: {
    socials,
  },
};
</script>

<style lang="scss" scoped>

.footer {
  background-color: var(--bg-header-footer);
  display: flex;
  flex-direction: column;
  align-items: center;

  &__logo {
    display: flex;
    margin: 80px 0 50px;

    @media (max-width: 450px) {
      margin: 48px 0 48px;
    }
    &__image {
      @media (max-width: 450px) {
        width: 113px;
        height: auto;
      }
    }
  }

  &__nav {
    display: flex;
    justify-content: space-evenly;
    max-width: 350px;
    width: 100%;
    margin-bottom: 64px;
    @media (max-width: 450px) {
      flex-direction: column;
      align-items: center;
      gap: 24px;
      margin-bottom: 48px;
    }

    &__item {
      font-family: "MontserratRegular";
      font-weight: 400;
      color: var(--header-text);
      font-size: 16px;
      line-height: 20px;
      text-decoration: none;
      transition: color 0.7s linear;
      &:hover {
        color: var(--light-blue);
      }

      &:active {
        color: var(--header-focus);
      }
    }
  }

  &__copyrights {
    font-family: "MontserratRegular";
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--header-focus);
    margin-bottom: 67px;
  }

  &__socials {
    display: none;
  }
}
</style>
