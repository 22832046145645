<template>
  <Teleport to="body">
    <div v-show="isOpen" id="modal" class="custom-modal">
      <div id="overlay_custom" class="custom-modal__overlay">
        <section class="custom-modal__modal">
          
          <slot />
          <span @click="close" class="close">+</span>
        </section>
      </div>
    </div>
        
  </Teleport>
</template>

<script>
export default {
  name: "BaseModal",
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: true,
    }
  },
  watch: {
    isActive: {
      handler(val) {
        this.isOpen= val;
      },
      immediate: true
    }
  },
  methods: {
    close() {
      this.isOpen= false;
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">

@keyframes fade {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.custom-modal__overlay {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.3);
  transition: all 100ms ease-in-out;
}

.custom-modal__modal {
  position: relative;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  // opacity: 0;
  transform: scale(0.95);
  animation: fade 150ms ease-out forwards;
  max-width: 800px;
  width: 100%;
  height: auto;
  margin: 0 20px;

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    z-index: 12;
    
    cursor: pointer;
    background-color: transparent;
    transform: rotate(45deg);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
  }
}

</style>

