<template>
  <div class="accordion" >
    <div
      class="accordion__header"
      :class="{ opened: accordionOpen || isOpen }"
      @click.self="openClick($event)"
    >
      <slot name="title"></slot>

      <div class="accordion__header__button-container" @click="openCloseClick">
        <span class="accordion__header__close" v-if="isOpen">
          <img
            src="../img/cross_accordion.svg"
            alt="cross"
            class="accordion__header__close__image"
          />
        </span>
        <span class="accordion__header__open" v-else>
          <img
            src="../img/arrow_accordion.svg"
            alt="arrow"
            class="accordion__header__open__image"
          />
        </span>
      </div>
    </div>

    <div class="accordion__content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  data() {
    return {
      isOpen: false,
      accordionsState: []
    };
  },
  methods: {
    openClick(e) {
      this.isOpen = true;
    },
    
    openCloseClick(e) {
      if(this.isOpen === false){
        this.isOpen = true
      }else if(this.isOpen === true){
        this.isOpen = false
        if(this.$route.hash === '#access-office'){
          this.$router.replace({hash: ''})
        }
      }

    },
  },
  created(){
    
  },
  
  mounted(){
    this.$router.replace({hash: ''})
  }
};
</script>

<style lang="scss" scoped>
.accordion {
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-bottom: 10px;

  

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--accordion-bg);
    padding: 15px 30px;
    cursor: pointer;

    &__button-container {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 450px) {
      padding: 12px 24px;
    }
  }

  &__content {
    // position: absolute;
    display: block;
    padding: 0;
    height: 0;
    overflow-y: hidden;
    // transition: all linear .7s;
  }
}
.accordion__header.opened {
  background-color: var(--accordion-content);
  padding-bottom: 0;
}
.accordion__header.opened + .accordion__content {

  height: auto;
  background-color: var(--accordion-content);
  padding: 0 30px 30px;
  @media (max-width: 450px) {
    padding: 0 24px 24px;
  }
}

.red {
  background-color: red;
}
</style>
