<template>
  <div class="fifth-block inner-container" id="about">
    <div class="fifth-block__first-part">
      <h2 class="fifth-block__first-part__title heading">
        {{ $t("fifth-block.title") }}
      </h2>
      <h3 class="fifth-block__first-part__subtitle">
        <span>FinBeat</span>{{ $t("fifth-block.subtitle") }}
      </h3>
      <p class="fifth-block__first-part__first-text">
        {{ $t("fifth-block.first-text") }}
        {{ $t("fifth-block.second-text") }}
      </p>
      <p class="fifth-block__first-part__second-text">
        {{ $t("fifth-block.third-text") }}
      </p>
    </div>

    <div class="fifth-block__second-part">
      <customAccordion>
        <template v-slot:title>
          <h3 class="accordion__header__title">
            {{ $t("fifth-block.acc.first.title") }}
          </h3>
        </template>
        <template v-slot:content>
          <p class="accordion__text"  >
            <span v-html="$t('fifth-block.acc.first.content')"> </span>
          </p>
          <div class="fifth-block__second-part_image">
            <!-- <img src="../img/fifth-block__second-part_image.png"> -->
          </div>
        </template>
      </customAccordion>

      <customAccordion>
        <template v-slot:title>
          <h3 class="accordion__header__title">
            {{ $t("fifth-block.acc.second.title") }}
          </h3>
        </template>
        <template v-slot:content>
          <div class="projects__links">
            <a class="accordion__text projects__links__item" @click="openModal('dwh')">DWH</a>
            <a class="accordion__text projects__links__item" @click="openModal('bigData')">FinBeat Data Collection</a>
            <a class="accordion__text projects__links__item" @click="openModal('creditBroker')">Credit broker</a>
            <a class="accordion__text projects__links__item" @click="openModal('finbeatCrm')">FinBeat CRM & Collection</a>
            <a class="accordion__text projects__links__item" @click="openModal('finbeatLms')">FinBeat Lms</a>
            <a class="accordion__text projects__links__item" @click="openModal('finbeatRisk')">FinBeat Risk</a>
            <a class="accordion__text projects__links__item" @click="openModal('mobileBank')">Мобильный банк</a>
          </div>
          <!-- <p class="accordion__text">
            {{ $t("fifth-block.acc.second.content") }}
          </p> -->
        </template>
      </customAccordion>

      <customAccordion>
        <template v-slot:title>
          <h3 class="accordion__header__title">
            {{ $t("fifth-block.acc.third.title") }}
          </h3>
        </template>
        <template v-slot:content>
          <div class="accordion__content geography">
            <p class="accordion__text accordion__content__text">
              {{ $t("fifth-block.acc.third.content") }}
            </p>
            <div class="accordion__content__image">
              <img
                v-if="lang() === 'ru' " 
                class="geography__bigger" 
                src="../img/map_ru.svg" 
                alt="map" 
              />
              <img
                v-else-if="lang() === 'en'"
                class="geography__bigger" 
                src="../img/map_en.svg" 
                alt="map" 
              />
              <img
                class="geography__smaller"
                src="../img/map-450.png"
                alt="map"
              />
            </div>
            <ul class="geography__list">
              <li class="geography__list__item">
                <span class="geography__list__item__num">1</span>
                <span class="geography__list__item__text">{{
                  $t("fifth-block.acc.third.locations.moscow")
                }}</span>
              </li>
              <li class="geography__list__item">
                <span class="geography__list__item__num">2</span>
                <span class="geography__list__item__text">{{
                  $t("fifth-block.acc.third.locations.spb")
                }}</span>
              </li>
              <li class="geography__list__item">
                <span class="geography__list__item__num">3</span>
                <span class="geography__list__item__text">{{
                  $t("fifth-block.acc.third.locations.vietnam")
                }}</span>
              </li>
              <li class="geography__list__item">
                <span class="geography__list__item__num">4</span>
                <span class="geography__list__item__text">{{
                  $t("fifth-block.acc.third.locations.dominican")
                }}</span>
              </li>
              <li class="geography__list__item">
                <span class="geography__list__item__num">5</span>
                <span class="geography__list__item__text">{{
                  $t("fifth-block.acc.third.locations.india")
                }}</span>
              </li>
              <li class="geography__list__item">
                <span class="geography__list__item__num">6</span>
                <span class="geography__list__item__text">{{
                  $t("fifth-block.acc.third.locations.italy")
                }}</span>
              </li>
              <li class="geography__list__item">
                <span class="geography__list__item__num">7</span>
                <span class="geography__list__item__text">{{
                  $t("fifth-block.acc.third.locations.colombia")
                }}</span>
              </li>
              <li class="geography__list__item">
                <span class="geography__list__item__num">8</span>
                <span class="geography__list__item__text">{{
                  $t("fifth-block.acc.third.locations.mexico")
                }}</span>
              </li>
              <li class="geography__list__item">
                <span class="geography__list__item__num">9</span>
                <span class="geography__list__item__text">{{
                  $t("fifth-block.acc.third.locations.peru")
                }}</span>
              </li>
            </ul>
          </div>

          <div class="">
            <p class="accordion__text">
              {{ $t("fifth-block.acc.third.text-1") }}
            </p>
            <p class="accordion__text">
              {{ $t("fifth-block.acc.third.text-2") }}
            </p>
            <p
               
              class="accordion__text"
              
              >
              {{ $t("fifth-block.acc.third.text-3")}}
              <a
              @click="scrollToElement('#access-office')"
                class="inserted-links"
                >{{ $t("fifth-block.acc.third.text-link")}}
              </a>
            </p>
          </div>
        </template>
      </customAccordion>

      <customAccordion ref="hello">
        <template v-slot:title>
          <h3 class="accordion__header__title">
            {{ $t("fifth-block.acc.fourth.title") }}
          </h3>
        </template>
        <template v-slot:content>
          <p
            v-html="$t('fifth-block.acc.fourth.content')" 
            class="accordion__text"
            >
          </p>
        </template>
      </customAccordion>

      <customAccordion>
        <template v-slot:title>
          <h3 class="accordion__header__title">
            {{ $t("fifth-block.acc.fifth.title") }}
          </h3>
        </template>
        <template v-slot:content>
          <p
            v-html="$t('fifth-block.acc.fifth.content')" 
            class="accordion__text"
            > 
          </p>
        </template>
      </customAccordion>

      <customAccordion>
        <template v-slot:title>
          <h3 class="accordion__header__title">
            {{ $t("fifth-block.acc.sixth.title") }}
          </h3>
        </template>
        <template v-slot:content>
          <h4 class="acc-title">{{ $t("fifth-block.acc.sixth.block-russia.title") }}</h4>
          <ul class="accordion__text conversation__list">
            <span>{{ $t("fifth-block.acc.sixth.block-russia.list-title") }}</span>
            <li class="conversation__list__item">
              {{ $t("fifth-block.acc.sixth.block-russia.list-item-1") }}
            </li>
            <li class="conversation__list__item">
              {{ $t("fifth-block.acc.sixth.block-russia.list-item-2") }}
            </li>
          </ul>

          <h4 class="acc-title">{{ $t("fifth-block.acc.sixth.block-serbia.title") }}</h4>
          <ul class="accordion__text conversation__list">
            <span>{{ $t("fifth-block.acc.sixth.block-serbia.list-title") }}</span>
            <li class="conversation__list__item">
              {{ $t("fifth-block.acc.sixth.block-serbia.list-item-1") }}
            </li>
            <li class="conversation__list__item">
              {{ $t("fifth-block.acc.sixth.block-serbia.list-item-2") }}
            </li>
            <li class="conversation__list__item">
              {{ $t("fifth-block.acc.sixth.block-serbia.list-item-3") }}
            </li>
          </ul>

          <ul class="accordion__text conversation__list">
            <span>{{ $t("fifth-block.acc.sixth.links.list-title") }}</span>
            <li class="conversation__list__item" v-html="$t('fifth-block.acc.sixth.links.list-item-1')"/>
            <li class="conversation__list__item" v-html="$t('fifth-block.acc.sixth.links.list-item-2')"/>
          </ul>
        </template>
      </customAccordion>

      <customAccordion>
        <template v-slot:title>
          <h3 class="accordion__header__title">
            {{ $t("fifth-block.acc.seventh.title") }}
          </h3>
        </template>
        <template v-slot:content>
          <div class="salary">
            <div class="salary__first-block">
              <ul class="accordion__text salary__first-block__list">
                <span>{{ $t("fifth-block.acc.seventh.list-title") }}</span>
                <li class="salary__first-block__list__item">
                  {{ $t("fifth-block.acc.seventh.list-item-1") }}
                </li>
                <li class="salary__first-block__list__item">
                  {{ $t("fifth-block.acc.seventh.list-item-2") }}
                </li>
              </ul>
              <p class="accordion__text">
                {{ $t("fifth-block.acc.seventh.text-1") }}
              </p>
              <p class="accordion__text">
                {{ $t("fifth-block.acc.seventh.text-2") }}
              </p>
              <div class="salary__first-block__image">
                <img
                  class="salary__first-block__image__big"
                  src="../img/projects.png"
                  alt="projects"
                />
                <img
                  class="salary__first-block__image__small"
                  src="../img/banks-logos.png"
                  alt="projects"
                />
              </div>
            </div>

            <div class="salary__second-block">
              <div class="salary__second-block__image">
                <img src="../img/salary.png" alt="salary" />
              </div>
              <div class="salary__second-block__info">
                <div class="salary__second-block__info__image">
                  <img src="../img/salary_info.svg" alt="info" />
                </div>
                <p class="salary__second-block__info__text">
                  {{ $t("fifth-block.acc.seventh.questions") }}
                </p>
              </div>
            </div>
          </div>
        </template>
      </customAccordion>

      <customAccordion>
        <template v-slot:title>
          <h3 class="accordion__header__title">
            {{ $t("fifth-block.acc.eighth.title") }}
          </h3>
        </template>
        <template v-slot:content>
          <ul class="accordion__text conversation__list">
            <span v-html="$t('fifth-block.acc.eighth.list-title-ru')"></span>
            <li class="conversation__list__item">
              {{ $t("fifth-block.acc.eighth.list-item-ru-1") }}
            </li>
            <li class="conversation__list__item">
              {{ $t("fifth-block.acc.eighth.list-item-ru-2") }}
            </li>
            <li class="conversation__list__item">
              {{ $t("fifth-block.acc.eighth.list-item-ru-3") }}
            </li>
            <li class="conversation__list__item">
              {{ $t("fifth-block.acc.eighth.list-item-ru-4") }}
            </li>
          </ul>

          <ul class="accordion__text conversation__list">
            <span v-html="$t('fifth-block.acc.eighth.list-title-serbia')"></span>
            <li class="conversation__list__item">
              {{ $t("fifth-block.acc.eighth.list-item-serbia-1") }}
            </li>
            <li class="conversation__list__item">
              {{ $t("fifth-block.acc.eighth.list-item-serbia-2") }}
            </li>
            <li class="conversation__list__item">
              {{ $t("fifth-block.acc.eighth.list-item-serbia-3") }}
            </li>
            <li class="conversation__list__item">
              {{ $t("fifth-block.acc.eighth.list-item-serbia-4") }}
            </li>
          </ul>

          <ol class="accordion__text conversation__list">
            <span v-html="$t('fifth-block.acc.eighth.vacation-info-list')"></span>
            <li class="conversation__list__item"
              v-html="$t('fifth-block.acc.eighth.vacation-info-list-item-1')"
              >
              
            </li>
            <li class="conversation__list__item">
              {{ $t("fifth-block.acc.eighth.vacation-info-list-item-2") }}
              <a @click="scrollToElement('#useful-links')" class="strong_links">{{ $t("fifth-block.acc.eighth.vacation-info-list-item-2-strong") }}</a>
              <strong>{{ $t("fifth-block.acc.eighth.vacation-info-list-item-2-end") }}</strong>
            </li>
          </ol>
          <p class="vacation" v-html="$t('fifth-block.acc.eighth.unpaid-vacation')"></p>

          <ol class="accordion__text conversation__list">
            <span v-html="$t('fifth-block.acc.eighth.unpaid-vacation-list')"></span>
            <li class="conversation__list__item">
              {{ $t("fifth-block.acc.eighth.unpaid-vacation-list-item-1") }}
            </li>
            <li class="conversation__list__item">
              {{ $t("fifth-block.acc.eighth.unpaid-vacation-list-item-2") }}
              <a @click="scrollToElement('#useful-links')" class="strong_links">{{ $t("fifth-block.acc.eighth.unpaid-vacation-list-item-2-strong") }}</a>
              <strong>{{ $t("fifth-block.acc.eighth.unpaid-vacation-list-item-2-end") }}</strong>
            </li>
          </ol>
        </template>
      </customAccordion>

      <customAccordion>
        <template v-slot:title>
          <h3 class="accordion__header__title insurance__title__big">
            {{ $t("fifth-block.acc.ninth.title-long") }}
          </h3>
          <h3 class="accordion__header__title insurance__title__small">
            {{ $t("fifth-block.acc.ninth.title-short") }}
          </h3>
        </template>
        <template v-slot:content>
          <div class="insurance">
            <div class="insurance__first-block">
              <p class="accordion__text">
                {{ $t("fifth-block.acc.ninth.text") }}
              </p>
              <h4 class="acc-title">{{ $t("fifth-block.acc.ninth.subtitle-ru") }}</h4>
              <p class="accordion__text">
                {{ $t("fifth-block.acc.ninth.text-ru") }}
              </p>
              <h4 class="acc-title">{{ $t("fifth-block.acc.ninth.subtitle-serbia") }}</h4>
              <p class="accordion__text">
                {{ $t("fifth-block.acc.ninth.text-serbia-1") }}
              </p>
              <p class="accordion__text margin-0"
                v-html="$t('fifth-block.acc.ninth.text-serbia-2')"
                >
                
              </p>
            </div>
            <div class="insurance__second-block">
              <img
                class="insurance__second-block__image"
                src="../img/insurance.png"
                alt="insurance"
              />
            </div>
          </div>
        </template>
      </customAccordion>

      <customAccordion>
        <template v-slot:title>
          <h3 class="accordion__header__title">
            {{ $t("fifth-block.acc.relocation.title") }}
          </h3>
        </template>
        <template v-slot:content>
          <p class="accordion__text">
            {{ $t("fifth-block.acc.relocation.text-1") }}
          </p>
          <p
            v-html="$t('fifth-block.acc.relocation.text-2')" 
            class="accordion__text"
            >
          </p>

          <p class="accordion__text">
            {{ $t("fifth-block.acc.relocation.text-3") }}
          </p>
          <p class="accordion__text">
            {{ $t("fifth-block.acc.relocation.text-4") }}
          </p>

          <p class="accordion__text">
            {{ $t("fifth-block.acc.relocation.text-5") }}
          </p>
          <ul class="accordion__text conversation__list">{{ $t("fifth-block.acc.relocation.list") }}
            <li class="conversation__list__item">{{ $t("fifth-block.acc.relocation.li-1") }}</li>
            <li class="conversation__list__item">{{ $t("fifth-block.acc.relocation.li-2") }}</li>
            <li class="conversation__list__item">{{ $t("fifth-block.acc.relocation.li-3") }}</li>
            <li class="conversation__list__item">{{ $t("fifth-block.acc.relocation.li-4") }}</li>
            <li class="conversation__list__item">{{ $t("fifth-block.acc.relocation.li-5") }}</li>
            <li class="conversation__list__item">{{ $t("fifth-block.acc.relocation.li-6") }}</li>
            <li class="conversation__list__item">{{ $t("fifth-block.acc.relocation.li-7") }}</li>
            <li class="conversation__list__item">{{ $t("fifth-block.acc.relocation.li-8") }}</li>
            <li class="conversation__list__item">{{ $t("fifth-block.acc.relocation.li-9") }}</li>
          </ul>
          <p class="accordion__text">
            {{ $t("fifth-block.acc.relocation.text-6") }}
          </p>
          <p class="accordion__text">
            {{ $t("fifth-block.acc.relocation.text-7") }}
          </p>
          <p
            v-html="$t('fifth-block.acc.relocation.text-8')" 
            class="accordion__text"
            >
            
          </p>
        </template>
      </customAccordion>


    </div>
    <BaseModal :is-active="modalIsOpen" @close="closeModal" >
      <template v-if="modalContext">
        
        <div class="modal__content">
            <div class="modal__container">
              <h2 class="modal__content__title">{{ $t(modalContext.title) }}</h2>
              <p class="modal__content__subtitle" v-if="modalContext.subtitle">{{ $t(modalContext.subtitle) }}</p>
              <p class="modal__content__subtitle" v-if="modalContext.subtitleFirst">{{ $t(modalContext.subtitleFirst) }}</p>
              <p class="modal__content__subtitle" v-if="modalContext.subtitleSecond">{{ $t(modalContext.subtitleSecond) }}</p>
              <p class="modal__content__subtitle" v-if="modalContext.subtitleThird">{{ $t(modalContext.subtitleThird) }}</p>
              <ul class="modal__content__subtitle-list" v-if="modalContext.subtitleList">{{ $t(modalContext.subtitleList) }}
                <li class="modal__content__subtitle-list-item" v-if="modalContext.subtitleListFirst">{{ $t(modalContext.subtitleListFirst)}}</li>
                <li class="modal__content__subtitle-list-item" v-if="modalContext.subtitleListSecond">{{ $t(modalContext.subtitleListSecond)}}</li>
                <li class="modal__content__subtitle-list-item" v-if="modalContext.subtitleListThird">{{ $t(modalContext.subtitleListThird)}}</li>
                <li class="modal__content__subtitle-list-item" v-if="modalContext.subtitleListFourth">{{ $t(modalContext.subtitleListFourth)}}</li>
                <li class="modal__content__subtitle-list-item" v-if="modalContext.subtitleListFifth">{{ $t(modalContext.subtitleListFifth)}}</li>
                <li class="modal__content__subtitle-list-item" v-if="modalContext.subtitleListSixth">{{ $t(modalContext.subtitleListSixth)}}</li>
                <li class="modal__content__subtitle-list-item" v-if="modalContext.subtitleListSeventh">{{ $t(modalContext.subtitleListSeventh)}}</li>
              </ul>
              <ul class="modal__content__list">{{ $t(modalContext.list)}}
                <li class="modal__content__list-item" v-if="modalContext.listFirst">{{ $t(modalContext.listFirst)}}</li>
                <li class="modal__content__list-item" v-if="modalContext.listSecond">{{ $t(modalContext.listSecond)}}
                  <ul class="modal__content__list-item__list">
                    <li class="modal__content__list-item__list-item" v-if="modalContext.listSecond1">{{ $t(modalContext.listSecond1)}}</li>
                    <li class="modal__content__list-item__list-item" v-if="modalContext.listSecond2">{{ $t(modalContext.listSecond2)}}</li>
                    <li class="modal__content__list-item__list-item" v-if="modalContext.listSecond3">{{ $t(modalContext.listSecond3)}}</li>
                  </ul>
                </li>
  
                <li class="modal__content__list-item" v-if="modalContext.listThird">{{ $t(modalContext.listThird)}}</li>
                <li class="modal__content__list-item" v-if="modalContext.listFourth">{{ $t(modalContext.listFourth)}}</li>
                <li class="modal__content__list-item" v-if="modalContext.listFifth">{{ $t(modalContext.listFifth)}}</li>
                <li class="modal__content__list-item" v-if="modalContext.listSixth">{{ $t(modalContext.listSixth)}}</li>
              </ul>
              <p class="modal__content__text" v-if="modalContext.text">{{ $t(modalContext.text)}}</p>
              <span class="modal__content__text-item" :class="{block: isBlock}" v-if="modalContext.textFirst">{{ $t(modalContext.textFirst)}}</span>
              <span class="modal__content__text-item" :class="{block: isBlock}" v-if="modalContext.textSecond">{{ $t(modalContext.textSecond)}}</span>
              <span class="modal__content__text-item" :class="{block: isBlock}" v-if="modalContext.textThird">{{ $t(modalContext.textThird)}}</span>
              <span class="modal__content__text-item" :class="{block: isBlock}" v-if="modalContext.textFourth">{{ $t(modalContext.textFourth)}}</span>
            </div>
          </div>
          
      </template>

    </BaseModal>

    
  </div>
  
</template>

<script>
import { useRoute, useRouter } from "vue-router";

import customAccordion from "./custom-accordion.vue";
import BaseModal from "@/components/Base/BaseModal";


const ARTICAL_KEY = {
  dwh: {
    title: "fifth-block.modal.dwh-title",
    subtitleFirst: "fifth-block.modal.dwh__subtitle-list-first-item",
    subtitleSecond: "fifth-block.modal.dwh__subtitle-list-second-item",
    list: "fifth-block.modal.dwh__list",
    listFirst: "fifth-block.modal.dwh__list__first-item",
    listSecond: "fifth-block.modal.dwh__list__second-item",
    listThird: "fifth-block.modal.dwh__list__third-item",
    text: "fifth-block.modal.dwh__text",
    textFirst: "t-sql, C#, Bash, Python, DAX."
  },
  bigData: {
    title: "fifth-block.modal.big-data-title",
    subtitle: "fifth-block.modal.big-data__subtitle",
    list: "fifth-block.modal.big-data__list",
    listFirst: "fifth-block.modal.big-data__list__first-item",
    listSecond: "fifth-block.modal.big-data__list__second-item",
    listThird: "fifth-block.modal.big-data__list__third-item",
    text: "fifth-block.modal.big-data__text",
    textFirst: "JavaScript"
  },
  creditBroker: {
    title: "fifth-block.modal.credit-broker-title",
    subtitle: "fifth-block.modal.credit-broker__subtitle",
    list: "fifth-block.modal.credit-broker__list",
    listFirst: "fifth-block.modal.credit-broker__list__first-item",
    listSecond: "fifth-block.modal.credit-broker__list__second-item",
    listThird: "fifth-block.modal.credit-broker__list__third-item",
    text: "fifth-block.modal.credit-broker__text",
    textFirst: "С# и фреймворки ReactJS, Angular."
  },
  finbeatCrm: {
    title: "fifth-block.modal.paylater-crm-title",
    subtitle: "fifth-block.modal.paylater-crm__subtitle",
    subtitleList: "fifth-block.modal.paylater-crm__subtitle-list",
    subtitleListFirst: "fifth-block.modal.paylater-crm__subtitle-list-first-item",
    subtitleListSecond: "fifth-block.modal.paylater-crm__subtitle-list-second-item",
    subtitleListThird: "fifth-block.modal.paylater-crm__subtitle-list-third-item",
    subtitleListFourth: "fifth-block.modal.paylater-crm__subtitle-list-fourth-item",
    subtitleListFifth: "fifth-block.modal.paylater-crm__subtitle-list-fifth-item",
    subtitleListSixth: "fifth-block.modal.paylater-crm__subtitle-list-sixth-item",
    subtitleListSeventh: "fifth-block.modal.paylater-crm__subtitle-list-seventh-item",
    list: "fifth-block.modal.paylater-crm__list",
    listFirst: "fifth-block.modal.paylater-crm__list__first-item",
    listSecond: "fifth-block.modal.paylater-crm__list__second-item",
    listThird: "fifth-block.modal.paylater-crm__list__third-item",
    listFourth: "fifth-block.modal.paylater-crm__list__fourth-item",
    listFifth: "fifth-block.modal.paylater-crm__list__fifth-item",
    text: "fifth-block.modal.paylater-crm__text",
    textFirst: "fifth-block.modal.paylater-crm__text__first",
    textSecond: "fifth-block.modal.paylater-crm__text__second",
    textThird: "fifth-block.modal.paylater-crm__text__third",
    textFourth: "fifth-block.modal.paylater-crm__text__fourth",
  },
  finbeatLms: {
    title: "fifth-block.modal.paylater-lms-title",
    subtitleFirst: "fifth-block.modal.paylater-lms__subtitle__first",
    subtitleSecond: "fifth-block.modal.paylater-lms__subtitle__second",
    subtitleThird: "fifth-block.modal.paylater-lms__subtitle__third",
    list: "fifth-block.modal.paylater-lms__list",
    listFirst: "fifth-block.modal.paylater-lms__list__first-item",
    listSecond: "fifth-block.modal.paylater-lms__list__second-item",
    listSecond1: "fifth-block.modal.paylater-lms__list__second-item-first",
    listSecond2: "fifth-block.modal.paylater-lms__list__second-item-second",
    listSecond3: "fifth-block.modal.paylater-lms__list__second-item-third",
    listThird: "fifth-block.modal.paylater-lms__list__third-item",
    listFourth: "fifth-block.modal.paylater-lms__list__fourth-item",
    listFifth: "fifth-block.modal.paylater-lms__list__fifth-item",
    listSixth: "fifth-block.modal.paylater-lms__list__sixth-item",
  },
  finbeatRisk: {
    title: "fifth-block.modal.paylater-risk-title",
    subtitleFirst: "fifth-block.modal.paylater-risk__subtitle__first",
    subtitleSecond: "fifth-block.modal.paylater-risk__subtitle__second",
    list: "fifth-block.modal.paylater-risk__list",
    listFirst: "fifth-block.modal.paylater-risk__list__first-item",
    listSecond: "fifth-block.modal.paylater-risk__list__second-item",
  },
  mobileBank: {
    title: "fifth-block.modal.mobile-bank-title",
    subtitleList: "fifth-block.modal.mobile-bank__subtitle-list",
    subtitleListFirst: "fifth-block.modal.mobile-bank__subtitle-list-first-item",
    subtitleListSecond: "fifth-block.modal.mobile-bank__subtitle-list-second-item",
    subtitleListThird: "fifth-block.modal.mobile-bank__subtitle-list-third-item",
    list: "fifth-block.modal.mobile-bank__list",
    listFirst: "fifth-block.modal.mobile-bank__list__first-item",
    listSecond: "fifth-block.modal.mobile-bank__list__second-item",
    listThird: "fifth-block.modal.mobile-bank__list__third-item",
    listFourth: "fifth-block.modal.mobile-bank__list__fourth-item",
    listFifth: "fifth-block.modal.mobile-bank__list__fifth-item",
    text: "fifth-block.modal.mobile-bank__text",
    textFirst: "fifth-block.modal.mobile-bank__text__first",
    textSecond: "fifth-block.modal.mobile-bank__text__second",
    textThird: "fifth-block.modal.mobile-bank__text__third",
  }

}

export default {

  name: "FifthBlock",
  components: {
    BaseModal,
    customAccordion
  },
  
  data(){
    return {
      modalIsOpen: false,
      modalContext: null,
      modalActive: false,
      isBlock: false,
      lang(){
        return localStorage.getItem('lang')
      },
      isRussianLang: true,
      accordionOpen: false
    }
  },

  setup(){
    const $router = useRouter();
    const $route = useRoute();

    return {
      $router,
      $route,
    }
  },

  
  
  methods: {
    openModal(project = ""){
      this.modalContext = ARTICAL_KEY[project]
      this.modalIsOpen = true;
      if(project === 'finbeatCrm'){
        this.isBlock = true
      }else if(project === 'mobileBank'){
        this.isBlock = true
      }else{
        this.isBlock = false
      }

    },
    closeModal(){
      this.modalIsOpen = false
      this.modalContext = null
    },

    scrollToElement(el){
      this.$router.replace({hash: el})
      if(this.$route.hash === el) { 
        this.$router.replace({hash: ''})       
      } 
      
      
    }

  },
  watch: {
    $route:{
      handler(newValue, oldValue){
        let el = document.getElementById('access-office')
     
        if(this.$route.hash === '#access-office') {
          el.__vueParentComponent.data.isOpen = true
        }else {
          el.__vueParentComponent.data.isOpen = false
        }
      }
    }
  }
  
}
</script>

<style lang="scss" scoped>

.fifth-block {
  &__first-part {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 90px;
    padding: 0 20px;
    position: relative;
    @media (max-width: 450px) {
      margin-bottom: 20px;
    }

    &__title {
      margin: 128px 0 40px;
      color: var(--light-blue);
      @media (max-width: 450px) {
        font-size: 24px;
        line-height: 32px;
        margin: 48px 0 20px;
      }
    }

    &__subtitle {
      font-size: 24px;
      font-family: "CommissionerLight";
      font-weight: 300;
      margin-top: 0;
      text-align: center;
      margin-bottom: 25px;
      color: var(--header-focus);

      @media (max-width: 450px) {
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 20px;
      }

      & > span {
        font-weight: 900;
      }
    }

    &__first-text {
      text-align: center;
      margin: 0 0 30px;
      font-family: "CommissionerLight";
      font-weight: 300;
      font-size: 24px;
      color: var(--header-focus);
      text-align: center;

      @media (max-width: 450px) {
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 20px;
      }
    }

    &__second-text {
      text-align: center;
      margin: 0 0 30px;
      font-family: "CommissionerLight";
      font-weight: 300;
      font-size: 24px;
      color: var(--header-focus);
      text-align: center;

      @media (max-width: 450px) {
        font-size: 16px;
        line-height: 18px;
        // margin-bottom: 20px;
      }
    }
  }

  &__second-part {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 128px;

    @media (max-width: 600px) {
      margin-bottom: 48px;
    }

    &_image {
      max-width: 1120px;

      & > img {
        width: 100%;
      }
    }
  }
}

.accordion {
  &__header__title {
    font-size: 24px;
    font-family: "MontserratMedium";
    font-weight: 500;
    color: var(--bg-header-footer);
    margin-right: 20px;

    @media (max-width: 450px) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__text {
    font-size: 20px;
    line-height: 24px;
    font-family: "CommissionerLight";
    font-weight: 300;
    color: var(--bg-header-footer);

    @media (max-width: 450px) {
      font-size: 16px;
      line-height: 18px;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;

    &__image {
      & > img {
        width: 100%;
      }
    }

    &__text {
      max-width: 400px;
    }
  }
}

.conversation {
  &__list {
    & > span {
      margin-left: -40px;
    }
  }

  &__link {
    font-weight: 700;
    color: var(--light-blue);
    text-decoration: none;
  }
}



.salary {
  display: flex;
  justify-content: space-between;

  &__first-block {
    max-width: 600px;
    // width: 100%;
    display: block;
    flex-direction: column;
    align-items: center;
    // width: 48%;

    &__image {
      max-width: 500px;

      &__big {
        display: block;

        @media (max-width: 400px) {
          display: none;
        }
      }

      &__small {
        display: none;

        @media (max-width: 400px) {
          display: block;
        }
      }

      & > img {
        width: 100%;
      }
    }

    &__list {
      & > span {
        display: block;
        margin-left: -40px;
      }
    }
  }

  &__second-block {
    width: 48%;
    @media (max-width: 1100px) {
      width: 100%;
    }

    @media (max-width: 450px) {
      display: flex;
      flex-direction: column-reverse;
    }

    &__image {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 500px;

      & > img {
        // max-width: 500px;
        width: 100%;
      }
    }

    &__info {
      color: var(--blue-02);
      @media (max-width: 450px) {
        display: flex;
        gap: 20px;
        margin-top: 20px;
      }

      &__image {
        margin: 20px 0 10px;

        & > img {
          @media (max-width: 450px) {
            width: 21px;
            height: 21px;
          }
        }

        @media (max-width: 450px) {
          margin: 0;
        }
      }

      &__text {
        font-family: "CommissionerLight";
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;

        @media (max-width: 450px) {
          margin: 0;
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
  }

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
}

.insurance {
  display: flex;
  // justify-content: space-between;
  gap: 32px;
  @media (max-width: 1100px) {
    flex-direction: column;
  }

  &__title {
    &__big {
      display: block;
      @media (max-width: 550px) {
        display: none;
      }
    }
    &__small {
      display: none;
      @media (max-width: 550px) {
        display: block;
      }
    }
  }

  &__first-block {
    max-width: 524px;
    width: 100%;
  }

  &__second-block {
    max-width: 500px;

    &__image {
      width: 100%;
    }
  }
}

.geography {
  &__bigger {
    display: block;

    @media (max-width: 450px) {
      display: none;
    }
  }

  &__smaller {
    display: none;

    @media (max-width: 450px) {
      display: block;
    }
  }

  &__list {
    display: none;
    margin-top: 27px;
    // margin: 27px 0 24px;
    padding-left: 0;

    @media (max-width: 450px) {
      display: block;
    }

    &__item {
      display: flex;
      // justify-content: space-between;
      gap: 8px;
      font-family: "CommissionerRegular";
      font-weight: 400;
      color: var(--bg-header-footer);
      font-size: 12px;
      line-height: 16px;
      list-style-type: none;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
      // text-decoration: none;

      &__num {
        width: 16px;
        height: 16px;

        border-radius: 50%;
        background-color: var(--light-blue);
        color: var(--accordion-content);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        line-height: 12px;
        font-family: "CommissionerRegular";
        font-weight: 400;
      }
    }
  }

  @media (max-width: 950px) {
    flex-direction: column;
  }
}

.vacation {
  font-size: 20px;
  line-height: 24px;
  font-family: "CommissionerLight";
  font-weight: 300;
  color: #3f4b4d;

  & > span {
  }
}

.projects__links {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  
  margin-top: 20px;

  @media (max-width: 470px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  

  &__item {
    color: #3f4b4d;
    font-weight: 600;
    font-size: 20px;
    width: 45%;
    text-align: center;
    padding: 15px 15px;
    background-color: var(--bg-4);
    cursor: pointer;

    @media (max-width: 470px) {
      width: 100%;
    }
  }
}

.conversation__link1 {
  background-color: red;
  font-weight: 700;
  color: var(--light-blue);
  text-decoration: none;
  
}

.modal__content {
  font-family: 'MontserratRegular';

  

  &__title {
    margin-bottom: 15px;
    @media (max-width: 450px) {
      font-size: 20px;
    }
  }

  &__subtitle {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    &-list {
      font-weight: 700;
      padding: 0;

      &-item {
        margin-left: 20px;
        font-weight: 400;
      }
    }
  }

  &__list {
    padding: 0;
    font-weight: 700;


    &-item {
      margin-left: 20px;
      font-weight: 400;
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }

      &__list-item {
        margin-bottom: 5px;
        // list-style-type: '-';
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__text {
    font-weight: 700;

    &-item {
      font-weight: 400;
      word-spacing: 5px;
    }
  }

  
}
.block {
  display: block;
}

.modal__container {
  
  @media (max-width: 600px) {
    max-height: 600px;
    overflow-y: scroll;
    // background-color: red;
  }
  @media (max-width: 400px) {
    max-height: 450px;
  }
}

.acc-title {
  font-size: 22px;
  line-height: 24px;
  font-family: "CommissionerLight";
  font-weight: 300;
  color: var(--bg-header-footer);
  margin: 30px 0 -20px;
}

.margin-0 {
  margin: 0;
}

.strong_links {
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  color: #6EC0CC;

}

</style>
