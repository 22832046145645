<template>
    <div class="first-block ">
        <div class="first-block__container">
            <div class="first-block__container__inner">
                <h1 class="first-block__container__inner__title"><span class="tt-cap">{{$t('first-block.title')}}!)</span></h1>
                <h2 class="first-block__container__inner__subtitle">{{ $t('first-block.subtitle', 1) }} - <strong>{{ $t('first-block.subtitle', 2) }}</strong></h2>
                <button class="first-block__container__inner__btn">
                    <svg   viewBox="0 0 64 64" fill="none" class="first-block__container__inner__btn__image">
                        <circle opacity="0.4" cx="32" cy="32" r="32" fill="#6EC0CC"/>
                            <g clip-path="url(#clip0_107_1058)">
                            <path d="M41.9 29.5L32.9 40.3V20H30.9V40.3L22.1 29.5L20.5 30.8L31.3 43.6C31.6 43.9 31.8 44 32 44C32.3 44 32.6 43.9 32.7 43.6L43.5 30.7L41.9 29.5Z" fill="#5E7073"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_107_1058">
                                <rect width="24" height="24" fill="white" transform="translate(20 20)"/>
                            </clipPath>
                        </defs>
                    </svg>
        
                </button>
            </div>
        </div>
    </div>
</template>

<script>

export default {


}
</script>


<style lang="scss" scoped>
    
    .first-block{
        overflow: hidden;
        height: auto;
        position: relative;

        &__container{
            
            background-image: url('../img/background-block1.webp');
            background-color: var(--bg-4);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            height: 904px;
            position: relative;
            z-index: -200;

            @media (max-width: 715px) {
                background-color: transparent;
                background-repeat: no-repeat;
                background-position: 66% 0;
                background-size: 175%;
                height: 778px;
            }
            @media (max-width: 660px){
                height: 725px;
            }
            @media (max-width: 600px){
                height: 660px;
            }
            @media (max-width: 545px){
                height: 593px;
            }
            @media (max-width: 475px){
                height: 517px;
            }
            @media (max-width: 400px){
                height: 437px;
            }
            @media (max-width: 350px){
                height: 384px;
            }
            @media (max-width: 320px){
                height: 350px;
            }
       

        &__inner{ 
            max-width: 1440px;
            width: 100%;
            margin: 0 auto;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            @media (max-width: 715px) {
                // margin-right: 50%;
                // top: 100px;  
                
            }
                      


        &__title{
            
            margin: 0 0 24px;
            color: #6EC0CC;
            font-size: 64px;
            font-family: 'MontserratLight';
            line-height: 78px;
            font-weight: 300;
            text-align: center;
            position: absolute;
            // temp
            top: 220px;
            left: 50%;
            transform: translateX(-50%);
            // 
            @media (max-width: 715px) {
                position: absolute;
                top: 48px;
                left: 50%;
                transform: translateX(-50%);
                font-size: 36px;
                line-height: 44px;
            }
        }

        &__subtitle{
            font-size: 20px;
            font-weight: 300;
            font-family: 'CommissionerLight';
            text-align: center;
            // temp
            position: absolute;
            top: 320px;
            left: 50%;
            transform: translateX(-50%);
            // 
            @media (max-width: 715px) {
                font-size: 16px;
                line-height: 18px;
                position: absolute;
                top: 110px;
                
            }

            & strong {
                font-weight: 900;
            }

        }

        &__btn{
            display: none;
            position: absolute;
            bottom: 14.6%;
            left: calc(50% - 32px);
            border: none;
            
            background-color: transparent;
            cursor: pointer;
            
            & circle, & path{
                transition: all linear .7s;
            }
            
            &:hover circle {
                fill: #6EC0CC;
            }
            &:hover path {
                fill: #F0F4F5;
            }

            &:focus circle{
                fill: #457980;
            }
            &:focus path{
                fill: #fff;
            }

            &__image{
                width: 64px;
                height: 64px;
                @media (max-width: 715px) {
                    width: 44px;
                    height: 44px;
                }
            }
            
        }
        }
    }
        


        


    }
</style>
