<template>
  <div class="header"  :class="{sticky: sticky}">
    <nav class="header__nav">
      <div class="header__nav__inner">
        <div class="header__nav__inner__first-item">
          <div class="hamburger-menu">
            <div
              class="menu__btn"
              @click="openBurger"
              :class="{ rotation: burgerIsOpen }"
            >
              <span class="menu__btn__string"></span>
            </div>
          </div>
          <a
            href="https://btrx.itexp.pro/stream/"
            class="header__nav__inner__first-item__link"
            >{{ $t("menu.bitrix") }}</a
          >
          <a
            href="https://personal.itexp.pro/"
            class="header__nav__inner__first-item__link"
            >{{ $t("menu.vacancy") }}</a
          >
        </div>

        <div class="header__nav__inner__second-item">
          <img
            src="../img/logo.svg"
            alt="it-expert-logo"
            class="header__nav__second-item__image"
          />
        </div>
        <div class="header__nav__inner__third-item">
          <button class="btn-lang" @click="switchLang">
            {{ $t("switchLang") }}
          </button>
        </div>
      </div>
      <ul class="menu__box" :class="{ translate: burgerIsOpen }">
        <li class="menu__box__item">
          <a
            href="https://btrx.itexp.pro/stream/"
            class="burger-link"
            >{{ $t("menu.bitrix") }}</a
          >
        </li>
        <li class="menu__box__item">
          <a href="https://personal.itexp.pro/" class="burger-link">{{
            $t("menu.vacancy")
          }}</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import socialsVue from "./socials.vue";

export default {
  data() {
    return {
      burgerIsOpen: false,
      sticky: false,
      initScroll: 0
    };
  },
  setup() {
    const { t, locale } = useI18n({ useScope: "global" });

    const switchLang = () => {
      locale.value === "en" ? (locale.value = "ru") : (locale.value = "en");
      localStorage.setItem("lang", locale.value);
    };
    return { t, switchLang };
  },
  components: {
    socialsVue,
  },
  methods: {
    openBurger() {
      this.burgerIsOpen = !this.burgerIsOpen;
    },
    handleScroll(event){
      if(window.scrollY > this.initScroll){
        this.sticky = true
      } else if(window.scrollY <= this.initScroll){
        this.sticky = false
      }
    }
  },
  created(){
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed(){
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style lang="scss" scoped>

.header {
  background-color: var(--bg-header-footer);
  background-position: center;


  &__nav {
    max-width: 1140px;
    margin: 0 auto;
    width: 100%;
    position: relative;
    z-index: 20;

    
    &__inner {
      background-color: var(--bg-header-footer);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      height: 80px;

      @media (max-width: 900px) {
        flex-direction: row-reverse;
        justify-content: space-between;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
      }

      &__first-item {
        max-width: 250px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-left: -40px;
        @media (max-width: 900px) {
          max-width: 30px;
          order: 3;
          justify-self: end;
        }

        &__link {
          text-decoration: none;
          font-size: 16px;
          color: var(--header-text);
          font-family: "MontserratRegular";
          transition: color linear 0.7s;
          @media (max-width: 900px) {
            display: none;
          }

          &:hover {
            color: var(--light-blue);
          }

          &:active {
            color: var(--header-focus);
          }
        }
      }

      &__second-item {
        // margin: 36px 0;
        @media (max-width: 900px) {
          justify-self: center;
          order: 2;
        }
        @media (max-width: 600px) {
          margin: 18px 0;
        }

        &__image {
          @media (max-width: 450px) {
            max-width: 113px;
            height: auto;
          }
        }
      }

      &__third-item {
        display: flex;
        gap: 20px;
        max-width: 250px;
        width: 100%;

        justify-content: flex-end;

        @media (max-width: 900px) {
          justify-self: start;
          order: 1;
          display: inline;
          max-width: none;
        }
      }
    }
  }
}

.btn-lang {
  border: none;
  cursor: pointer;
  padding: 0 3px;
  background-color: #3f4b4d;
  font-size: 16px;
  color: #92adb3;
  font-family: "MontserratRegular";
  text-transform: uppercase;
  transition: color 0.7s linear;

  &:hover {
    color: var(--light-blue);
  }

  &:active {
    color: var(--header-focus);
  }
}

.hamburger-menu {
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu__btn {
  position: relative;
  display: none;
  cursor: pointer;
  gap: 4px;
  width: 30px;
  height: 20px;
  @media (max-width: 900px) {
    display: block;
  }

  &:active .menu__btn__string{
    background-color: var(--header-focus);
  }
  
  &:active .menu__btn__string:before{
    background-color: var(--header-focus);
  }

  &:active .menu__btn__string:after{
    background-color: var(--header-focus);
  }

  &__string {
    margin-top: 8px;
    order: 2;
  }
  &__string,
  &__string::before,
  &__string::after {
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    background-color: var(--header-text);


  }

  &__string::before {
    content: "";
    top: -8px;
  }

  &__string::after {
    content: "";
    top: 8px;
  }

  &.rotation > .menu__btn__string {
    transform: rotate(45deg);
  }
  &.rotation > .menu__btn__string::before {
    top: 0;
    transform: rotate(0);
  }
  &.rotation > .menu__btn__string::after {
    top: 0;
    transform: rotate(90deg);
  }
}

.menu__box {
  position: absolute;
  transform: translateY(-150%);
  top: 0;
  left: 0;
  transition: transform 0.7s linear;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-header-footer);
  padding: 48px 0;
  z-index: -100;

  &__item {
    list-style-type: none;
    &:hover {
      color: var(--light-blue);
    }

    &:first-child {
      margin-bottom: 30px;
    }
  }
  @media (min-width: 901px) {
    display: none;
  }
}

.translate {
  transform: translateY(59px);
  @media (max-width: 600px) {
    transform: translateY(59px);
  }
  // @media (max-width: 450px) {
  //   transform: translateY(57px);
  // }
}
.burger-link {
  text-decoration: none;
  font-size: 16px;
  color: var(--header-text);
  font-family: "MontserratRegular";
  transition: color linear 0.7s;
  &:hover {
    color: var(--light-blue);
  }
  &:active {
    color: var(--header-focus);
  }
}

//sticky header
.sticky{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
}

.sticky {
  .header__nav__inner {
    height: 75px;
  }
}


</style>
