<template>
  <div>
    <headerVue/>
    <firstBlock/>
    <secondBlock/>
    <thirdBlock/>
    <fourthBlock/>
    <fifthBlock/>
    <sixthBlock/>
    <corporateCulture/>
    <resources/>
    <seventhBlock/>
    <firstDay/>
    <usefulLinks/>
    <eightBlock/>
    <footerVue/>
    
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

import headerVue from './components/header.vue';
import firstBlock from './components/first-block.vue';
import secondBlock from './components/second-block.vue';
import thirdBlock from './components/third-block.vue';
import fourthBlock from './components/fourth-block.vue';
import fifthBlock from './components/fifth-block.vue';
import sixthBlock from './components/sixth-block.vue';
import corporateCulture from './components/corporate-culture.vue';
import resources from './components/resources.vue';
import seventhBlock from './components/seventh-block.vue';
import firstDay from './components/first-day.vue';
import usefulLinks from './components/useful-links.vue';
import eightBlock from './components/eighth-block.vue'
import footerVue from './components/footer.vue';




export default {


setup(){
  const { t} = useI18n({useScope: 'global'})
  return {t}
},
  name: 'App',
  components: {
    headerVue,
    firstBlock,
    secondBlock,
    thirdBlock,
    fourthBlock,
    fifthBlock,
    sixthBlock,
    corporateCulture,
    resources,
    seventhBlock,
    firstDay,
    usefulLinks,
    eightBlock,
    footerVue
    
  }
}
</script>

