<template>
    <div class="socials">
        <a href="#" class="socials__item">
            <img src="../img/telegram.svg" alt="telegram" class="socials__item__image telegram">
        </a>

        <a href="#" class="socials__item">
            <img src="../img/linkedin.svg" alt="linkedin" class="socials__item__image linkedin">
        </a>

        <a href="#" class="socials__item">
            <img src="../img/facebook.svg" alt="facebook" class="socials__item__image facebook">
        </a>
    </div>
</template>


<script>

export default {
    
}
</script>


<style lang="scss">

    .socials{
        &__item{
            margin-right: 16px;
            width: 24px;
            height: 24px;

            &:last-child{
                margin-right: 0;
            }

            &:hover .socials__item__image{
                   
                stroke: #6EC0CC;
            }

            
        }
    }

</style>