<template>
  <div class="fourth-block" id="content-block">
    <h2 class="fourth-block__title heading">
      <span class="tt-cap">{{ $t("fourth-block.title") }}</span>
    </h2>
    <div class="fourth-block__links inner-container">
      <a href="#about" class="link">
        <div class="fourth-block__links__item" tabindex="0">
          <span class="fourth-block__links__item__num">01</span>
          <h3 class="fourth-block__links__item__title">
            {{ $t("fourth-block.first-item-title") }}
          </h3>
          <p class="fourth-block__links__item__text">
            {{ $t("fourth-block.first-item-subtitle") }}
          </p>
        </div>
      </a>

      <a href="#corporate-culture" class="link">
        <div class="fourth-block__links__item" tabindex="0">
          <span class="fourth-block__links__item__num">02</span>
          <h3 class="fourth-block__links__item__title">
            {{ $t("fourth-block.second-item-title") }}
          </h3>
          <p class="fourth-block__links__item__text">
            {{ $t("fourth-block.second-item-subtitle") }}
          </p>
        </div>
      </a>

      <a href="#resouces" class="link">
        <div class="fourth-block__links__item" tabindex="0">
          <span class="fourth-block__links__item__num">03</span>
          <h3 class="fourth-block__links__item__title">
            {{ $t("fourth-block.third-item-title") }}
          </h3>
          <p class="fourth-block__links__item__text">
            {{ $t("fourth-block.third-item-subtitle") }}
          </p>
        </div>
      </a>

      <a href="#first-day" class="link">
        <div class="fourth-block__links__item" tabindex="0">
          <span class="fourth-block__links__item__num">04</span>
          <h3 class="fourth-block__links__item__title">
            {{ $t("fourth-block.fourth-item-title") }}
          </h3>
          <p class="fourth-block__links__item__text">
            {{ $t("fourth-block.fourth-item-subtitle") }}
          </p>
        </div>
      </a>

      <a href="#useful-links" class="link">
        <div class="fourth-block__links__item" tabindex="0">
          <span class="fourth-block__links__item__num">05</span>
          <h3 class="fourth-block__links__item__title">
            {{ $t("fourth-block.fifth-item-title") }}
          </h3>
          <p class="fourth-block__links__item__text">
            {{ $t("fourth-block.fifth-item-subtitle") }}
          </p>
        </div>
      </a>

      <a href="#eighth-block" class="link">
        <div class="fourth-block__links__item" tabindex="0">
          <span class="fourth-block__links__item__num">06</span>
          <h3 class="fourth-block__links__item__title">
            {{ $t("fourth-block.sixth-item-title") }}
          </h3>
          <p class="fourth-block__links__item__text">
            {{ $t("fourth-block.sixth-item-subtitle") }}
          </p>
        </div>
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.fourth-block {
  background-color: var(--bg-4);
  display: flex;
  flex-direction: column;
  align-items: center;


  &__title {
    margin-top: 128px;

    color: var(--header-focus);

    @media (max-width: 450px) {
      font-size: 24px;
      margin: 48px 0 0;
    }
  }

  &__links {
    margin: 40px 0 128px;
    display: flex;
    justify-content: center;
    // padding: 0 20px;
    gap: 32px;
    // padding: 20px;
    flex-wrap: wrap;

    @media (max-width: 450px) {
      margin: 20px 0 48px;
      gap: 10px;
    }

    &__item {
      box-sizing: border-box;
      max-width: 352px;
      width: 100%;
      min-height: 296px;
      height: 100%;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      padding: 0 20px;
      transition: background-color 0.7s linear;

      &:hover {
        background-color: var(--accordion-content);
      }
      &:focus {
        background-color: var(--color-num);
      }
      // padding: 20px;
      @media (max-width: 450px) {
        max-width: 288px;
        min-height: 182px;
      }

      &__num {
        // padding: 100px 32px 0;
        color: var(--color-num);
        text-align: center;
        font-size: 24px;
        font-family: "MontserratRegular";
        // padding: 108px 32px 0;
        @media (max-width: 450px) {
          margin-top: 24px;
          font-size: 20px;
        }
      }

      &__title {
        text-align: center;
        min-width: 288px;
        font-size: 24px;
        font-family: "MontserratRegular";
        color: var(--header-focus);
        font-weight: 400;
        line-height: 32px;
        width: 100px;
        padding: 0 32px;
        @media (max-width: 450px) {
          font-size: 20px;
          line-height: 24px;
          margin: 8px 0 24px;
        }
      }

      &__text {
        display: none;
        font-family: "CommissionerLight";
        font-weight: 300;
        font-size: 16px;
        line-height: 18px;
        color: var(--header-focus);
        text-align: center;
        margin-top: 0;

        @media (max-width: 450px) {
          display: block;
          margin-bottom: 24px;
        }
      }
    }
  }
}

.link {
  text-decoration: none;
}
</style>
