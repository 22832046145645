<template>
  <div class="resources " id="resouces">
    <div class="resources__align">
      <h2 class="resources__title">{{ $t("resources.title") }}</h2>
      <div class="resources__content">
        <customAccordion
          
          >
          <template v-slot:title>
            <h3 class="accordion__header__title">
              {{ $t("resources.acc.crm.title") }}
            </h3>
          </template>
          <template v-slot:content>
            <p class="accordion__text">
              {{ $t("resources.acc.crm.text-1") }}
            </p>
            <p class="accordion__text">
              {{ $t("resources.acc.crm.text-2") }}
            </p>
          </template>
        </customAccordion>

        <customAccordion
          
          >
          <template v-slot:title>
            <h3 class="accordion__header__title">
              {{ $t("resources.acc.mail.title") }}
            </h3>
          </template>
          <template v-slot:content>
            <p class="accordion__text">
              {{ $t("resources.acc.mail.text") }}
            </p>
          </template>
        </customAccordion>

        <customAccordion
          
          >
          <template v-slot:title>
            <h3 class="accordion__header__title">
              {{ $t("resources.acc.password.title") }}
            </h3>
          </template>
          <template v-slot:content>
            <p class="accordion__text">
              {{ $t("resources.acc.password.text") }}
            </p>
          </template>
        </customAccordion>

        <customAccordion
          
          >
          <template v-slot:title>
            <h3 class="accordion__header__title">
              {{ $t("resources.acc.parking.title") }}
            </h3>
          </template>
          <template v-slot:content>
            <p class="accordion__text">
              {{ $t("resources.acc.parking.text") }}
            </p>
          </template>
        </customAccordion>

        <customAccordion :id="id"
          
          >
          <template v-slot:title>
            <h3 class="accordion__header__title">
              {{ $t("resources.acc.access.title") }}
            </h3>
          </template>
          <template v-slot:content>
            <p class="accordion__text">
              {{ $t("resources.acc.access.text") }}
            </p>
          </template>
        </customAccordion>

        <div class="resources__content__iframe-box">
          <iframe
            class="resources__content__iframe-box__item"
            src="https://www.youtube.com/embed/mSVmK4Alve8"
            frameborder="0"
          ></iframe>
        </div>

        <div class="resources__content__image">
          <img src="../img/resources-image.png" alt="coding" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import customAccordion from "./custom-accordion.vue";
export default {
  components: {
    customAccordion,
  },
  data(){
    return {
      accordionOpen: false,
      id: 'access-office',
      accordionsState: []
    }
  },
};
</script>

<style lang="scss">


.resources {
  background-color: var(--bg-4);

  &__align {
    max-width: 1120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    margin: 0 auto;

    @media (max-width: 1200px) {
      width: auto;
    }
  }
  &__title {
    color: var(--header-focus);
    margin: 100px 0 30px;
    text-align: center;
    font-family: "MontserratRegular";
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;

    @media (max-width: 450px) {
      font-size: 24px;
      line-height: 32px;
      margin: 48px 0 24px;
    }
  }

  &__content {
    // padding: 0 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__iframe-box {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%;

      &__item {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }

      margin: 40px 0 50px;
    }

    &__image {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 100px;
      max-width: 756px;

      & > img {
        width: 100%;
      }

      @media (max-width: 450px) {
        margin-bottom: 50px;
      }
    }
  }
}

.accordion {
  &__header__title {
    font-size: 24px;
    font-family: "MontserratMedium";
    font-weight: 500;
    color: var(--bg-header-footer);
    // margin-bottom: 30px;

    @media (max-width: 450px) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__text {
    font-size: 20px;
    line-height: 24px;
    font-family: "CommissionerLight";
    font-weight: 300;
    color: var(--bg-header-footer);
    margin-top: 30px;

    @media (max-width: 450px) {
      font-size: 16px;
      line-height: 18px;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;

    &__text {
      max-width: 400px;
    }
  }
}

.fake-iframe {
  max-width: inherit;

  & > img {
    width: 100%;
  }
}
</style>
