import VueRouter from 'vue-router'

import App from '../App.vue'
import resources from '../components/resources.vue'
import { createRouter, createWebHistory } from 'vue-router'


const router = createRouter({
    routes: [
        {
            path: '/',
            component: App
        },
        {
            path: '/resources/:id',
            component: resources
        }
    ],
    history: createWebHistory(),
    scrollBehavior(to, from){
        if (to.hash) {
            return {
              el: to.hash,
              top: 75,
              behavior: 'smooth'
            }
        }
    }
})
export default router