<template>
    <div class="third-block ">
        <div class="third-block__container">
            <div class="third-block__container__inner"></div>
        </div>
        <!-- <img class="third-block__image" src="../img/third-block_bg.png" alt=""> -->
    </div>

</template>

<style lang="scss">
    .third-block{
        overflow: hidden;
        height: auto;       
        position: relative;        

        &__container{
            background-image: url('../img/third-block_bg.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            height: 904px;


            @media (max-width: 715px) {
                background-repeat: no-repeat;
                background-position: 62% 0;
                background-size: 175%;
                height: 778px;
                
            }
            @media (max-width: 660px){
                height: 725px;
            }
            @media (max-width: 600px){
                height: 660px;
            }
            @media (max-width: 545px){
                height: 593px;
            }
            @media (max-width: 475px){
                height: 517px;
            }
            @media (max-width: 400px){
                height: 437px;
            }
            @media (max-width: 350px){
                height: 384px;
            }
            @media (max-width: 320px){
                height: 350px;
            }


        &__inner{ 
            max-width: 1440px;
            width: 100%;
            margin: 0 auto;
            position: relative;
            // top: 200px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // align-items: center;
            height: 100%;
            @media (max-width: 715px) {
                // margin-right: 50%;
                // top: 100px;  
                
            }
        }
    }
}        
</style>
