<template>
    <div class="second-block container">
        <div class="second-block__text">
            <p class="second-block__text-first" v-html="$t('second-block.first-text')"></p>
            <p class="second-block__text-second" v-html="$t('second-block.second-text')"></p>
        </div>
        <img src="../img/second_block-bg.png" alt="background" class="second-block__image">
    </div>    
</template>

<script>
export default {
    
}
</script>


<style lang="scss" scoped>
    .second-block{
        display: flex;
        flex-direction: column;
        align-items: center;
        // height: 100vh;
        
        @media (max-width: 325px) {
            margin-bottom: 30px;
        }
        

        &__text{
            padding: 0 30px;
            margin: 128px 0 40px;
            font-size: 20px;
            font-weight: 300;
            display: flex;
            flex-direction: column;
            align-items: center;
            line-height: 24px;
            font-family: 'CommissionerLight';
            @media (max-width: 715px) {
                margin-top: 48px;
            }

            &-first{
                // max-width: 800px;
                // width: 100%;
                text-align: center;
                margin-bottom: 30px;
            }

            &-second{
                text-align: center;

                & > span{
                font-weight: 500;
                color: #6EC0CC;
                }
            }
   
        }

        &__image{
            max-width: 756px;
            width: 100%;
            
            
        }
    }
    

    
</style>