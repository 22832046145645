<template>
  <div class="useful-links " id="useful-links">
    <div class="inner-container">
      <h2 class="useful-links__title">{{ $t("useful-links.title") }}</h2>
      <div class="useful-links__content">
        <div class="useful-links__content__container">

          <a class="useful-links__content__container__item" href="https://btrx.itexp.pro/bitrix/tools/disk/focus.php?folderId=41208&action=openFolderList&ncc=1" target="_blank">
            <h3 class="useful-links__content__container__item__title">
              {{ $t("useful-links.live-application") }}
            </h3>
            <img
              src="../img/useful-links_page.svg"
              alt="page icon"
              class="useful-links__content__container__item__image"
            />
          </a>

          <a class="useful-links__content__container__item" href="https://btrx.itexp.pro/bitrix/tools/disk/focus.php?folderId=36463&action=openFolderList&ncc=1" target="_blank">
            <h3 class="useful-links__content__container__item__title">
              {{ $t("useful-links.inducation") }}
            </h3>
            <img
              src="../img/useful-links_page.svg"
              alt="page icon"
              class="useful-links__content__container__item__image"
            />
          </a>

          <a class="useful-links__content__container__item" href="https://btrx.itexp.pro/bitrix/tools/disk/focus.php?folderId=36464&action=openFolderList&ncc=1" target="_blank">
            <h3 class="useful-links__content__container__item__title">
              {{ $t("useful-links.fire-safety") }}
            </h3>
            <img
              src="../img/useful-links_page.svg"
              alt="page icon"
              class="useful-links__content__container__item__image"
            />
          </a>

          <a class="useful-links__content__container__item" href="https://btrx.itexp.pro/bitrix/tools/disk/focus.php?folderId=36465&action=openFolderList&ncc=1" target="_blank">
            <h3 class="useful-links__content__container__item__title">
              {{ $t("useful-links.electrical-safety") }}
            </h3>
            <img
              src="../img/useful-links_page.svg"
              alt="page icon"
              class="useful-links__content__container__item__image"
            />
          </a>

          <a class="useful-links__content__container__item" href="https://btrx.itexp.pro/bitrix/tools/disk/focus.php?folderId=36466&action=openFolderList&ncc=1" target="_blank">
            <h3 class="useful-links__content__container__item__title">
              {{ $t("useful-links.official-document-form") }}
            </h3>
            <img
              src="../img/useful-links_page.svg"
              alt="page icon"
              class="useful-links__content__container__item__image"
            />
          </a>
        </div>

        <div class="useful-links__content__container">

          <!-- <a class="useful-links__content__container__item" href="https://btrx.itexp.pro/bitrix/tools/disk/focus.php?folderId=36467&action=openFolderList&ncc=1" target="_blank">
            <h3 class="useful-links__content__container__item__title">
              {{ $t("useful-links.official-letterhead") }}
            </h3>
            <img
              src="../img/useful-links_page.svg"
              alt="page icon"
              class="useful-links__content__container__item__image"
            />
          </a> -->

          <a class="useful-links__content__container__item" href="https://btrx.itexp.pro/bitrix/tools/disk/focus.php?folderId=36461&action=openFolderList&ncc=1" target="_blank">
            <h3 class="useful-links__content__container__item__title">
              {{ $t("useful-links.company-details") }}
            </h3>
            <img
              src="../img/useful-links_page.svg"
              alt="page icon"
              class="useful-links__content__container__item__image"
            />
          </a>

          <a class="useful-links__content__container__item" href="https://btrx.itexp.pro/company/vis_structure.php" target="_blank">
            <h3 class="useful-links__content__container__item__title">
              {{ $t("useful-links.company-structure") }}
            </h3>
            <img
              src="../img/useful-links_page.svg"
              alt="page icon"
              class="useful-links__content__container__item__image"
            />
          </a>

          <a class="useful-links__content__container__item" href="https://personal.itexp.pro/" target="_blank" style="pointer-events: none;">
            <h3 class="useful-links__content__container__item__title">
              {{ $t("useful-links.career-website") }}
            </h3>
            <img
              src="../img/useful-links_page.svg"
              alt="page icon"
              class="useful-links__content__container__item__image"
            />
          </a>

          <a class="useful-links__content__container__item" href="https://helpdesk.bitrix24.ru/open/14999860" target="_blank">
            <h3 class="useful-links__content__container__item__title">
              {{ $t("useful-links.bitrix24-mobile-app") }}
            </h3>
            <img
              src="../img/useful-links_page.svg"
              alt="page icon"
              class="useful-links__content__container__item__image"
            />
          </a>

          <a class="useful-links__content__container__item" href="https://drive.google.com/file/d/1SbZ_YN1iBv6pyFbkTxc1f8f5OVe_3Snm/view?usp=sharing" target="_blank">
            <h3 class="useful-links__content__container__item__title">
              {{ $t("useful-links.consent") }}
            </h3>
            <img
              src="../img/useful-links_page.svg"
              alt="page icon"
              class="useful-links__content__container__item__image"
            />
          </a>

        </div>
      </div>
    </div>
  </div>
</template>

<script></script>

<style lang="scss" scoped>

.useful-links {
  background-color: var(--bg-4);
  // padding: 0 20px 0;

  &__title {
    padding: 100px 0 40px;
    text-align: center;
    font-size: 36px;
    margin: 0;
    line-height: 44px;
    font-family: "MontserratRegular";
    font-weight: 400;
    color: var(--header-focus);
    @media (max-width: 450px) {
      font-size: 24px;
      line-height: 32px;
      padding: 48px 0 24px;
    }
  }

  &__content {

    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    gap: 32px;
    padding-bottom: 128px;
    align-items: start;
    @media (max-width: 750px) {
      flex-direction: column;
      gap: 20px;
    }
    @media (max-width: 450px) {
      gap: 8px;
      padding-bottom: 48px;
    }

    &__container {
      max-width: 544px;
      // width: 44%;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      @media (max-width: 760px) {
        align-items: center;
        padding: 0 20px;
      }
      @media (max-width: 450px) {
        gap: 8px;
      }

      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        height: 88px;
        border: 1px solid var(--color-num);
        text-decoration: none;
        transition: background-color 0.7s linear;
        &:hover {
          background-color: var(--accordion-bg);
        }
        &:active {
          background-color: var(--color-num);
        }
        @media (max-width: 760px) {
          max-width: 288px;
          width: 100%;
        }
        @media (max-width: 450px) {
          padding: 0 20px;
        }

        &__title {
          font-size: 20px;
          line-height: 24px;
          font-family: "CommissionerRegular";
          font-weight: 400;
          color: var(--header-focus);
          margin: 0;

          @media (max-width: 760px) {
            font-size: 18px;
            line-height: 20px;
          }
          @media (max-width: 400px) {
            font-size: 16px;
            line-height: 18px;
          }
        }
        &__image {
          @media (max-width: 450px) {
            width: 24px;
          }
        }
      }
    }
  }
}
</style>
