<template>
  <div class="eighth-block " id="eighth-block">
    <div class="inner-container eighth-block-flex">
      <!-- <h2 class="eighth-block__title">Спасибо, что прочел наш <span >welcome book!</span></h2> -->
      <h2 class="eighth-block__title" v-html="$t('eighth-block.title')"></h2>
      <p class="eighth-block__text">
        {{ $t("eighth-block.text") }}
      </p>
      <div class="eighth-block__image">
        <img src="../img/eighth-block_image.png" />
      </div>
    </div>
  </div>
</template>

<script></script>

<style lang="scss" scoped>

.eighth-block {
  background-color: var(--accordion-content);
  padding-top: 128px;

  &-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    // padding: 128px auto 40px;
    text-align: center;
    font-family: "MontserratRegular";
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    color: var(--header-focus);
    margin-bottom: 40px;
    padding: 0 20px;
    @media (max-width: 450px) {
      font-size: 24px;
      line-height: 32px;
    }
    & > span {
      font-weight: 600;
    }
  }

  &__text {
    font-family: "CommissionerLight";
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: var(--header-focus);
    text-align: center;
    margin: 0 0 40px;
    padding: 0 20px;
    @media (max-width: 450px) {
      font-size: 16px;
      line-height: 18px;
    }
  }

  &__image {
    max-width: 756px;
    // width: 100%;
    margin-bottom: 128px;

    & > img {
      width: 100%;
    }
  }
}


</style>
