
import { createApp } from 'vue'
import App from './App.vue'


import router from './router/index'

import "@/assets/styles/main.scss"


import { languages } from './i18n'
import { defaultLocale } from './i18n'
import { createI18n, useI18n } from 'vue-i18n'


const messages = Object.assign(languages)
const localStorageLang = localStorage.getItem('lang')

// const router = VueRouter.createRouter({
    
//     // history: VueRouter.createWebHashHistory(),
//     routes, 
// })

const i18n = createI18n({
    legacy: false,
    locale: localStorageLang || defaultLocale,
    fallbackLocale: 'en',
    messages 
})


// createApp(App, {
//     setup(){
//         const {t} = useI18n()
//         // const $router = useRouter();
//         // const $route = useRoute();
//         return t
//     }
// }).use(i18n).use(router).mount('#app')

const app = createApp(App)

app.use(i18n)
app.use(router)

app.mount('#app')