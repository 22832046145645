<template>
  <div class="first-day" id="first-day">
    <h2 class="first-day__title">{{ $t("first-day.title") }}</h2>

    <p 
      v-for="(item, index) in tm('first-day.content')" 
      :key="index" 
      class="first-day__text" 
      v-html="rt(item)"
    />
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { rt, tm } = useI18n();
    
    return { rt, tm }
  },  
}
</script>


<style lang="scss">
.first-day {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;

  &__title {
    font-family: "MontserratRegular";
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    color: var(--light-blue);
    margin: 128px 0 40px;
    text-align: center;
    padding: 0 20px;

    @media (max-width: 500px) {
      font-size: 24px;
      line-height: 32px;
      margin: 48px 0 24px;
    }
  }

  &__text {
    text-align: center;
    font-family: "CommissionerLight";
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: var(--header-focus);
    margin-bottom: 25px;

    @media (max-width: 1200px) {
      padding: 0 20px;
    }

    @media (max-width: 500px) {
      font-size: 16px;
      line-height: 18px;
    }

    &:last-child {
      margin-bottom: 128px;

      @media (max-width: 500px) {
        margin-bottom: 48px;
      }
    }
  }

  &__link {
    text-decoration: none;
    font-weight: 600;
    color: var(--light-blue);
  }
}

.link {
  &-long {
    display: block;
    
    @media (max-width: 450px) {
      display: none;
    }
  }

  &-short {
    display: none;

    @media (max-width: 450px) {
      display: block;
    }
  }
}
</style>
